(function ($) {
    $.fn.goTo = function () {
        $('html, body').animate({
            scrollTop: $(this).offset().top + 'px'
        }, 'fast');
        return this; // for chaining...
    }
})(jQuery);
var lastScrollTop = 0;
var timer, seccion;
$(window).scroll(function () {
    if (timer) {
        window.clearTimeout(timer);
    }
    timer = window.setTimeout(function () {
        var top = $(window).scrollTop();
        var divBottom = $('#quienes').offset().top + $('#quienes').outerHeight() / 2;
        var exito_heigt = $('#exito').offset().top + $('#exito').outerHeight();
        if ((divBottom > top) && (($('#hero').offset().top) < top)) {
            $("#quienes span").removeClass("animated");
            $("#quienes span:odd").removeClass("fadeOutDown");
            $("#quienes span:even").removeClass("fadeOutUp");
            var impar = "animated fadeInDown";
            var par = "animated fadeInUp";
            $("#quienes span:odd").addClass(impar);
            $("#quienes span:even").addClass(par);
        } else {
            $("#quienes span").removeClass("animated");
            $("#quienes span:odd").removeClass("fadeInDown");
            $("#quienes span:even").removeClass("fadeInUp");
            var impar = "animated fadeOutDown";
            var par = "animated fadeOutUp";
            $("#quienes span:odd").addClass(impar);
            $("#quienes span:even").addClass(par);
        }
        if ((exito_heigt > top) && (($('#exito').offset().top - 200) < top)) {
            $("#exito span").removeClass("animated");
            $("#exito span:odd").removeClass("fadeOutDown");
            $("#exito span:even").removeClass("fadeOutUp");
            var impar = "animated fadeInDown";
            var par = "animated fadeInUp";
            $("#exito span:odd").addClass(impar);
            $("#exito span:even").addClass(par);
        } else {
            $("#exito span").removeClass("animated");
            $("#exito span:odd").removeClass("fadeInDown");
            $("#exito span:even").removeClass("fadeInUp");
            var impar = "animated fadeOutDown";
            var par = "animated fadeOutUp";
            $("#exito span:odd").addClass(impar);
            $("#exito span:even").addClass(par);
        }
        var st = window.pageYOffset || document.documentElement.scrollTop; // Credits: "https://github.com/qeremy/so/blob/master/so.dom.js#L426"
        var cutoff = $(window).scrollTop();
        var section;
        $('section').each(function () {
            console.log($(this).attr("id"));
            console.log(top);
            console.log($(this).offset().top);
            console.log()
            if ($(this).offset().top + $(this).height() > cutoff - 175) {
                $('.section').removeClass('current')
                $(this).addClass('current');
                section = $(this);
                console.log(this);
                return false;
            }
        });
        if (st > lastScrollTop) {
            try {
                section.closest('section').next().goTo();
            } catch (error) {}
        } else {

        }
        try {
            var current = section.closest('section').next().attr("id");
            var color = dict[current];
            if (color === "negro") {
                $(".nav_right").removeClass("white");
                $(".nav_right").addClass("black");
                $(".nav-toggle i").css("background", "white");
                $(".nav-toggle i").removeClass("whiteMagic");
                $(".nav-toggle i").addClass("blackMagic");
                $(".svgMagic").removeClass("whiteFill");
                $(".svgMagic").addClass("blackFill");
            } else {
                $(".nav_right").removeClass("black");
                $(".nav-toggle i").css("background", "rgb(102, 102, 102)");
                $(".nav_right").addClass("white");
                $(".nav-toggle i").removeClass("blackMagic");
                $(".svgMagic").addClass("whiteFill");
                $(".nav-toggle i").addClass("whiteMagic");
                $(".svgMagic").removeClass("blackFill");
            }
        } catch (error) {

        }
        lastScrollTop = st <= 0 ? 0 : st;
    }, 250);
});
new WOW().init();
var dict = {
    "hero": "negro",
    "quienes": "blanco",
    "proceso": "negro",
    "descanso": "blanco",
    "servicios": "negro",
    "exito": "blanco",
    "project": "negro"
};
// Helper function for add element box list in WOW
WOW.prototype.addBox = function (element) {
    this.boxes.push(element);
};
// Init WOW.js and get instance
var wow = new WOW();
wow.init();
// Attach scrollSpy to .wow elements for detect view exit events,
// then reset elements and add again for animation
$('.wow').on('scrollSpy:exit', function () {
    $(this).css({
        'visibility': 'hidden',
        'animation-name': 'none'
    }).removeClass('animated');
    wow.addBox(this);
}).scrollSpy();
$(".middle_section").click(function () {
    var siguiente = $(this).closest('section').next();
    scroll(siguiente);
});
$(".send2contact").click(function () {
    scroll($("footer"));
});
$(".nav_right").click(function () {
    $("#menu").css("transform", "translate(0)");
});
$("#close").click(function () {
    $("#menu").css("transform", "translate(-100%)");
    setTimeout(function () {
        $("#menu").css("display", "none");
        $("#menu").css("transform", "translate(100%)");
        setTimeout(function () {
            $("#menu").css("display", "flex");
        }, 2000);
    }, 2000);
});

$("#modal").iziModal({
    title: 'Descarga nuestros catálogos',
    subtitle: '',
    headerColor: '#88A0B9',
    background: null,
    theme: '',  // light
    icon: null,
    iconText: null,
    iconColor: '',
    fullscreen: false,
    openFullscreen: false,
    closeOnEscape: true,
    closeButton: true,
    appendTo: 'body', // or false
    appendToOverlay: 'body', // or false
    overlay: true,
    overlayClose: true,
    overlayColor: 'rgba(0, 0, 0, 0.9)'});
$(".pikele").click(function () {
    var box = $(this).data("section");
    console.log(box);
    if (box.includes("index.html")){
        window.location.replace(box);
    }
    scroll($(box));
    $("#menu").css("transform", "translate(-100%)");
    setTimeout(function () {
        $("#menu").css("display", "none");
        $("#menu").css("transform", "translate(100%)");
        setTimeout(function () {
            $("#menu").css("display", "flex");
        }, 2000);
    }, 2000);
});


$("#catalogos").click(function () {
    $("#menu").css("transform", "translate(-100%)");
    setTimeout(function () {
        $("#menu").css("display", "none");
        $("#menu").css("transform", "translate(100%)");
        setTimeout(function () {
            $("#menu").css("display", "flex");
        }, 2000);
    }, 2000);
    $('#modal').iziModal('open');
});

var width = $("body").width();
if (width > 800) {
    var height = $("#get_height").height();
    $("#set_height").css("height", height);
}

$(document).on('click', 'a[href^="#"]', function(e) {
    // target element id
    var id = $(this).attr('href');

    // target element
    var $id = $(id);
    if ($id.length === 0) {
        return;
    }

    // prevent standard hash navigation (avoid blinking in IE)
    e.preventDefault();

    // top position relative to the document
    var pos = $id.offset().top;

    // animated top scrolling
    $('body, html').animate({scrollTop: pos});
});

function scroll(element){
    var pos = element.offset().top;
    $('body, html').animate({scrollTop: pos});
}



// code for my custom made slider
$(document).ready(function() {
    var hero01 = "url(dist/images/foto_home.jpg)";
    var hero02 = "url(dist/images/cel.jpg)";
    var hero03 = "url(dist/images/audifonos.jpg)";
    var a = "";

    function preload(arrayOfImages) {
        $(arrayOfImages).each(function() {
            $('<img/>')[0].src = this;
            // Alternatively you could use:
            // (new Image()).src = this;
        });
    }

    // Usage:

    preload([
        'dist/images/foto_home.jpg',
        'dist/images/cel.jpg',
        'dist/images/audifonos.jpg'
    ]);

    function loop(a) {
        $("#hero").css("background-image", a);
    }
    loop();
    var refreshIntervalId = setInterval(function() {
        loop(a)
    }, 500);
    var counter = 0;
    var state = 0;
    var override = 0;

    function controller(state, override) {
        //console.log(state);
        if (state === 1) {
            counter = override;
            //console.log("override!!!!!!!!!!!!!!!")
            state = 0;
        }
        counter++;
        if (counter < 3) {
            a = hero01;
        } else if (counter < 6) {
            a = hero02;
        } else if (counter < 9) {
            a = hero03;
        } else {
            counter = 0;
        }
        console.log(counter);
    }

    function createInterval(controller, state, override, interval) {
        setInterval(function() {
            controller(state, override);
        }, interval);
    }
    createInterval(controller, state, override, 1500);

    $('#right_arrow').click(function() {
        counter  = counter + 4;
    });
    $('#left_arrow').click(function() {
        counter  = counter - 5;
        if (counter  < 0){
            counter = 7;
        }
    });
});